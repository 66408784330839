import { StatusCodes } from 'http-status-codes';

export class DatabaseStateError extends Error {
  constructor(message?: string, options?: ErrorOptions) {
    super(message, options);
  }
}

export type PermissionsErrorName =
  | 'NO_USER_FOUND'
  | 'INVALID_USER_ROLE'
  | 'INSUFFICIENT_PERMISSIONS';

export class PermissionsError extends Error {
  message: string;
  name: PermissionsErrorName;
  cause: unknown;

  constructor({
    message,
    name,
    cause,
  }: {
    message: string;
    name: PermissionsErrorName;
    cause?: unknown;
  }) {
    super();
    this.message = message;
    this.name = name;
    this.cause = cause;
  }
}

export class ApiError extends Error {
  message: string;
  statusCode: StatusCodes;

  constructor(message: string, statusCode: StatusCodes) {
    super();
    this.message = message;
    this.statusCode = statusCode;
  }
}
